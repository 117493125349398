import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby";
import {css} from "@emotion/react";

export default function NotFound() {
    return (
        <Layout>
            <div>
                <h1
                    css={css`
            display: inline-block;
            border-bottom: 1px solid;
          `}
                >
                    Jean-Baptiste MITTET
                </h1>
                <p>Oh now! We didn't find the page you were looking for</p>
                <Link to="/">Why not return to the home page?</Link>
            </div>
        </Layout>
    )
}